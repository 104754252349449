#root > div > div > div > div > div > form > div > fieldset > span {
  position: initial;
  width: initial;
  height: initial;
}

#root > div > div > div > div > div > form > div > fieldset > span > legend {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: x-large;
  font-weight: bold;
  color: #043495;
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #043495;
  --amplify-components-button-primary-hover-background-color: #043972;
  --amplify-components-button-primary-focus-background-color: #3874b5;
  --amplify-components-button-primary-active-background-color: #3874b5;
  --amplify-components-button-link-color: #043495;
  --amplify-components-button-link-hover-color: #043972;
  --amplify-components-button-link-focus-color: #3874b5;
  --amplify-components-button-link-active-color: #3874b5;
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-hover-background-color: transparent;
}

// body > div.MuiDialog-root.MuiModal-root.css-zw3mfo-MuiModal-root-MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container > div {
//   width: 100% !important;
// }